@import '../../../App.sass';
@import '../../../mq.sass';

.faqs {

    .faq-section-title {
        font-size: 40px;
        color: $v4green1;
        font-weight: bold;
        margin-bottom: 2rem;

        @include mobile {
            font-size: 28px; }

        @include tablet {
            font-size: 32px; } }

    .faq-wrapper {

        .accordion {
            margin-bottom: 1rem;
            border: 1px solid $v4green1;
            border-radius: 2px;
            cursor: pointer;

            .card {
                border: none;
                border-radius: 5px;

                .card-header {
                    background-color: #ffffff;
                    padding: .5rem 2rem;
                    align-items: center;

                    .question {
                        font-size: 18px;
                        font-family: $fontMerriweather;
                        color: #1B544E;
                        margin-bottom: 0;
                        padding-right: 1rem;

                        @include mobile {
                            font-size: 14px; }

                        @include tablet {
                            font-size: 16px; } }


                    .icon {
                        color: #1B544E; } }

                .card-body {
                    padding: 1rem 2rem;
                    border-top: 2px solid #c4c4c4;

                    .answer {
                        font-size: 18px;
                        font-family: $fontMerriweather;
                        color: #1B544E;
                        margin-bottom: 0;

                        @include mobile {
                            font-size: 14px; }

                        @include tablet {
                            font-size: 16px; } } } } } } }

