@import '../../../../App.sass';
@import '../../../../mq.sass';

.plan-card {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 275px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

    .card-body {
        padding: 1.5rem 1rem;

        .name {
            font-size: 18px;
            color: $v4green1;
            text-align: center;
            margin-bottom: 1.25rem; }

        .benefits {
            color: $v4green1;

            .benefit {
                font-size: 12px;
                margin-bottom: 0; }

            .dot {
                margin-right: 1rem;
                font-size: 8px; } } }

    .card-footer {
        background-color: $v4green1;
        color: #ffffff;
        text-align: center;

        .price {
            font-weight: bold;
            margin-bottom: .5rem; } } }
