@import '../../mq.sass';

.line-wrapper {
    .line {
        padding: 0 4rem;

        @include mobile {
            padding: 0 15px; }

        @include tablet {
            padding: 0 15px; }

        .separator {
            margin: 2.5rem 0;
            border-bottom: 1px solid #dfdfdf;

            @include mobile {
                margin: 1rem 0; }

            @include tablet {
                margin: 1.5rem 0; } } } }

