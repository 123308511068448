@import "../../../App.sass";
@import "../../../mq.sass";

.customers {

    .title {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: bold;
        color: $v4green1;

        @include mobile {
            font-size: 28px; }

        @include tablet {
            font-size: 32px; } }

    .subtitle {
        width: 600px;
        font-size: 18px;
        margin-bottom: 0;
        color: $v4green1;
        font-family: $fontMerriweather;

        @include mobile {
            font-size: 14px;
            width: 100%;
            padding-right: 2rem; }

        @include tablet {
            font-size: 16px;
            width: 500px; } }

    .customer-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 2rem;

        @include mobile {
            justify-content: center; }

        @include tablet {
            justify-content: center; }

        .logo-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 50px;

            img {
                width: 100px;
                height: auto; }

            #icon-11 {
                width: auto;
                height: 70px; }

            #icon-14 {
                width: auto;
                height: 50px; } } } }
