@import '../../../../App.sass';
@import '../../../../mq.sass';

.carousel {
    height: 100%;
    width: 100%;

    .slides {
        height: 100%;

        .slick-track {
            height: 100%;

            .slick-slide {
                height: 100%;

                div:first-child {
                    height: 100%;

                    .slide-wrapper {

                        .slide {
                            display: flex; }

                        img {
                            height: 100%;
                            width: auto; }

                        .text-wrapper {
                            padding: 2rem;
                            height: 100%;
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;

                            @include mobile {
                                padding: 0 1rem; }

                            @include tablet {
                                padding: .5rem 1rem; }

                            .testimony-message {
                                flex-grow: 1;
                                display: flex;
                                flex-direction: column;
                                font-size: 18px;
                                color: $v4green1;

                                @include mobile {
                                    font-size: 14px; }

                                @include tablet {
                                    font-size: 16px; }

                                p {
                                    @include mobile {
                                        margin-bottom: 0; } }

                                .text {
                                    padding: 0 1rem;

                                    @include mobile {
                                        padding: 0 .5rem; } }

                                .icon {
                                    color: $v4green1; } }

                            .testimony-profile {

                                @include mobile {
                                    display: none; }

                                @include tablet {
                                    display: flex;
                                    gap: 1rem; }

                                p {
                                    font-size: 18px;
                                    color: $v4green1;

                                    @include mobile {
                                        margin-bottom: 0; }

                                    @include tablet {
                                        margin-bottom: 0; } }

                                .name, .company {
                                    font-weight: bold;
                                    margin-bottom: .25rem; }

                                .position {
                                    font-family: $fontMerriweather; } } } } }

                div:first-child:focus {
                    outline: none; } } } } }





.slick-list {
    width: 100%; }

.slick-dots {
    bottom: -50px; }

.slick-dots .slick-active span {
    color: $v4orange2; }

.slick-thumb li span {
    color: $v4green1; }

.slick-thumb li span:hover {
    color: $v4orange1; }

.slick-arrow {
    height: 50px;
    width: 50px;
    font-size: 25px;
    color: #ffffff; }

.slick-arrow:hover {
    color: $v4orange1; }

.slick-arrow:active {
    color: $v4orange2; }

.navigation-icon {
    display: block; }

.slick-prev {
    left: -50px; }

.slick-next {
    right: -50px; }

.slick-slide {
    height: 100%; }
