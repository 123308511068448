@import '../../../App.sass';
@import '../../../mq.sass';

.custom-tab.nav.nav-tabs {
    margin-bottom: 2rem; }

.custom-tab {
    justify-content: center;

    .tab {
        font-size: 20px;
        font-weight: bold;
        color: #c4c4c4; }

    // .tab.nav-tabs .nav-link:hover
    //     border-color: transparent transparent transparent

    .tab:focus {
        border: 1px solid transparent; }

    .tab.nav-item.nav-link.active {
        border: none;
        color: $v4green1;
        border-bottom: 3px solid $v4green1; } }

.tab-content {

    .tab-pane {

        .plan-cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 2rem; } } }
