@import '../../../../App.sass';
@import '../../../../mq.sass';

.product-feature-card {
    margin-bottom: 4rem;

    @include mobile {
        margin-bottom: 1rem; }

    @include tablet {
        margin-bottom: 2rem; }

    .feature-name {
        font-size: 40px;
        font-weight: bold;
        color: $v4green1;

        @include mobile {
            font-size: 28px; }

        @include tablet {
            font-size: 32px; } }

    .feature-info {
        display: flex;

        @include mobile {
            flex-direction: column; }

        img {
            height: 250px;
            max-width: 500px;
            border-radius: 3px;
            margin-right: 75px;
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

            @include mobile {
                width: 100%;
                height: auto; }

            @include tablet {
                margin-right: 2rem;
                width: auto;
                height: 200px; } }

        .feature-description {
            padding-top: 1.5rem;

            @include mobile {
                padding: 0; }

            @include tablet {
                padding: 0; }

            .caption {
                font-size: 28px;
                font-weight: bold;
                color: $v4green1;

                @include mobile {
                    font-size: 20px;
                    margin-bottom: 0; }

                @include tablet {
                    font-size: 24px;
                    margin-bottom: 0; } }

            .description {
                font-size: 18px;
                color: $v4grey1;
                max-width: 400px;
                text-align: justify; } } } }

