@import "../../../App.sass";
@import '../../../mq.sass';

.product {
    position: relative;
    width: 100%;

    .title {
        font-size: 40px;
        margin-bottom: 1rem;
        font-weight: bold;
        color: #ffffff;

        @include mobile {
            font-size: 28px;
            margin-bottom: .5rem; }

        @include tablet {
            font-size: 32px;
            margin-bottom: .5rem; } }

    .subtitle {
        width: 600px;
        font-size: 18px;
        margin-bottom: 0;
        color: #ffffff;
        font-family: $fontMerriweather;

        @include mobile {
            width: 100%;
            padding-right: 2rem; }

        @include tablet {
            width: 500px; } }

    .product-image {
        position: relative;
        width: 700px;
        top: -40px;
        left: -100px;

        @include mobile {
            width: 300px;
            top: -20px;
            left: 0; }

        @include tablet {
            width: 500px;
            top: -50px;
            left: 0; } }

    .product-brief {
        position: absolute;
        top: 175px;
        left: 550px;
        width: 450px;
        font-size: 13px;
        color: #ffffff;
        font-family: $fontMerriweather;
        /*text-align: justify*/

        @include mobile {
            font-size: 14px;
            color: $v4green1;
            width: 100%;
            padding-right: 2rem;
            top: 350px;
            left: 0; }

        @include tablet {
            font-size: 16px;
            top: 100px;
            left: 400px;
            width: 300px; } }

    .buttons {
        position: absolute;
        top: 50vh;
        left: 550px;

        @include mobile {
            top: 78vh;
            left: 0; }

        @include tablet {
            top: 35vh;
            left: 400px; }

        button {
            margin-right: 1rem; } } }


