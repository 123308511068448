@import '../../../../App.sass';
@import '../../../../mq.sass';


.feature-card {
    width: 270px;
    text-align: center;
    margin: 0;

    @include mobile {
        width: 200px; }

    @include tablet {
        width: 250px; }

    .image-wrapper {
        width: 100%;
        padding: .5rem;
        height: auto;
        margin: 0;

        img {
            width: 90px; } }

    .feature-name {
        font-size: 18px;
        color: $v4green1;
        margin-bottom: .5rem;
        font-family: $fontMerriweather;
        font-weight: bold;

        @include mobile {
            font-size: 14px; }

        @include tablet {
            font-size: 16px; } }

    .feature-desc {
        font-size: 14px;
        font-family: $fontMerriweather;
        color: $v4grey1;
        line-height: 18px;

        @include mobile {
            font-size: 10px; }

        @include tablet {
            font-size: 12px; } } }
