@import '../../../App.sass';
@import '../../../mq.sass';

.modal {
    overflow: hidden;

    .product-modal {

        .modal-content {
            top: 25px;
            left: 0;
            border: none;
            min-width: 500px;
            max-height: 350px;
            border-radius: 3px;
            background-color: #ffffff;

            @include mobile {
                min-width: 100%;
                max-height: none; }

            .modal-body {
                padding: 2rem;
                max-height: 300px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 1rem;

                @include mobile {
                    width: 100%;
                    max-height: none; }

                a {
                    text-decoration: none; }

                .product {
                    padding: .2rem;
                    width: 200px;
                    height: 60px;

                    @include mobile {
                        width: 100%; }

                    .info {
                        width: 100%;
                        display: inline-block;


                        .name {
                            margin-bottom: 0;
                            color: $v4green1;
                            font-size: 14px;
                            font-weight: bold; }

                        .desc {
                            margin-bottom: 0;
                            font-size: 12px;
                            text-align: justify;
                            color: #000000; } } }

                .product:hover {
                    background-color: #eaeaea;
                    border-radius: 2px;
                    -webkit-transition: all ease .3s;
                    -moz-transition: all ease .3s;
                    -o-transition: all ease .3s;
                    transition: all ease .3s; } } } } }

.modal-backdrop {
    top: 55px !important;
    overflow: hidden; }
