@import "../../App.sass";
@import "../../mq.sass";

.product-page-wrapper {

    .product-page {
        #bg {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            filter: brightness(50%); }

        .page-head-wrapper {
            padding-top: 6rem;
            height: 100vh;

            .page-head {} }

        .feature-section-wrapper {
            padding-top: 2rem;

            .feature-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; } } }

        .video-section-wrapper {
            padding-bottom: 4rem;

            .video-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; }

                .section-title {
                    color: $v4green1;
                    font-weight: bold;
                    font-size: 40px;
                    margin-bottom: 1rem; }

                @include mobile {
                    font-size: 28px; }

                @include tablet {
                    font-size: 32px; }

                .section-subtitle {
                    color: $v4green1;
                    font-size: 18px;
                    margin-bottom: 2rem;
                    width: 600px;

                    @include mobile {
                        font-size: 14px;
                        width: 100%;
                        padding-right: 2rem; }

                    @include tablet {
                        font-size: 16px;
                        width: 500px; } }

                .video-player {} } }

        .banner-section-wrapper {
            margin-top: 4rem; //hapus jika video sudah ditampilkan
            margin-bottom: 4rem;

            .banner-section {
                width: 100%;
                height: 275px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                .bg {
                    z-index: -1;
                    position: absolute;
                    top: 0;
                    left: 0; }

                .banner {
                    text-align: center;

                    .banner-title {
                        font-size: 40px;
                        color: #ffffff;
                        font-weight: bold;
                        margin-bottom: 2rem;

                        @include mobile {
                            font-size: 28px; }

                        @include tablet {
                            font-size: 32px; } } } } }

        .pricing-section-wrapper {

            .pricing-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; }

                .section-title {
                    color: $v4green1;
                    font-size: 40px;
                    font-weight: bold;
                    margin: 0;

                    @include mobile {
                        font-size: 28px; }

                    @include tablet {
                        font-size: 32px; } }

                .section-subtitle {
                    color: $v4green1;
                    font-size: 18px;
                    width: 600px;

                    @include mobile {
                        font-size: 14px;
                        width: 100%;
                        padding-right: 2rem; }

                    @include tablet {
                        font-size: 16px;
                        width: 500px; } } } }


        .faq-section-wrapper {
            margin-bottom: 4rem;

            .faq-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; } } } } }

