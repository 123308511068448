$small: 578px !default; // Mobile
$medium: 768px !default; //Tablet
$large: 992px !default; //Desktop Small
$extra-large: 1200px !default; //Desktop Wide

@mixin mobile {
    @media screen and (max-width: $medium - 1) { // <=768
        @content; } }

@mixin tablet {
    @media screen and (min-width: $medium) and (max-width: $large - 1) {
        @content; } }

@mixin desktop {
    @media screen and (min-width: $large) {
        @content; } }

@mixin desktop-only {
    @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
        @content; } }

@mixin desktop-wide {
    @media screen and (min-width: $extra-large) {
        @content; } }
