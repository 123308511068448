@import '../../../../mq.sass';
@import '../../../../App.sass';

.topic-card {
    height: 300px;
    width: 280px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

    @include mobile {
        height: 250px;
        width: 220px; }

    @include tablet {
        height: 280px;
        width: 240px; }


    .topic-card-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .meta {
            color: $v4grey1;
            font-family: $fontMerriweather;
            font-size: 12px;
            margin-bottom: 0;

            @include mobile {
                font-size: 10px; }

            @include tablet {
                font-size: 10px; } }

        .topic-card-title {
            flex-grow: 1;
            font-family: $fontMerriweather;
            color: $v4green1;
            font-size: 18px;

            @include mobile {
                font-size: 14px; }

            @include tablet {
                font-size: 16px; } }

        .topic-card-content {
            color: $v4grey1;
            font-family: $fontMerriweather;
            font-size: 14px;

            @include mobile {
                font-size: 10px; }

            @include tablet {
                font-size: 12px; } } } }
