@import '../../../App.sass';
@import '../../../mq.sass';

.custom-modal.modal-dialog {
    max-width: 600px;

    .modal-content {
        height: 477px;

        .modal-body {
            padding: 0;
            display: flex;

            .left {
                min-height: 100%;
                width: 50%;
                border-radius: 3px 0 0 3px;

                .left-content {
                    padding: 2rem;

                    @include mobile;
                    padding: 1rem;

                    .form-title {
                        font-size: 22px;
                        color: $v4green1;
                        font-weight: bold;
                        line-height: 26px;
                        text-align: center;

                        @include mobile {
                            font-size: 16px; } }

                    .form-group {
                        margin-bottom: .5rem;

                        .form-label {
                            margin: 0;
                            font-size: 14px;
                            color: $v4green1;
                            font-weight: bold;

                            @include mobile {
                                font-size: 12px; } }

                        .form-control {
                            min-height: 30px;
                            font-size: 14px;
                            border-radius: 3px;
                            border: 1px solid #9C9C9C;
                            background-color: #EFEFEF;
                            height: calc(1.5em + .60rem + 2px);
                            padding: .3rem .75rem;

                            @include mobile {
                                font-size: 10px; } }


                        .form-control:focus {
                            border: 1px solid $v4green1;
                            background-color: #ffffff;
                            box-shadow: none; }


                        .form-control:visited {
                            border: 1px solid $v4green1;
                            background-color: #ffffff; }

                        .form-check {
                            display: flex;
                            align-items: baseline;

                            .form-check-label {
                                font-size: 10px;
                                color: $v4green1;
                                line-height: 20px;
                                font-weight: bold; } }

                        .forgot-password {
                            display: flex;
                            a {
                                color: $v4green1;
                                font-size: 10px;
                                font-weight: bold;
                                line-height: 20px; } } }

                    .endform {
                        display: flex;
                        justify-content: space-between;

                        @include mobile {
                            flex-direction: column;
                            justify-content: flex-start; } }

                    .submit {
                        background-color: $v4green1;
                        color: #ffffff;
                        width: 100%;
                        height: 35px;
                        font-size: 14px;
                        font-weight: bold;
                        border: none;
                        border-radius: 3px;

                        @include mobile {
                            margin-bottom: 1rem; } }

                    .google-label {
                        padding: 2rem 3rem 1rem;
                        font-size: 22px;
                        color: $v4green1;
                        font-weight: bold;
                        text-align: center;
                        line-height: 26px;

                        @include mobile {
                            font-size: 16px;
                            padding: 1rem 0 0;
                            display: none; } }

                    .google-button {
                        position: relative;
                        background-color: $v4green1;
                        color: #ffffff;
                        width: 100%;
                        height: 35px;
                        font-size: 14px;
                        font-weight: bold;
                        border: none;
                        border-radius: 3px;

                        @include mobile {
                            font-size: 10px; }

                        .icon {
                            float: left;
                            position: relative;
                            left: 10px;
                            width: 22px;
                            height: 22px;
                            display: inline;
                            background-color: #ffffff;
                            padding: 5px;
                            border-radius: 50%;

                            @include mobile {
                                display: none; }

                            img {
                                position: relative;
                                top: -5px;
                                left: -1px;
                                width: 15px; } } } } }

            .right {
                width: 50%;
                height: 100%;
                background: linear-gradient(239.76deg, #649d66 0%, #06623B 101.58%);
                border-radius: 0 3px 3px 0;
                position: relative;

                .right-content {
                    padding: 2rem;
                    text-align: center;
                    position: relative;
                    top: 15px;

                    @include mobile {
                        padding: 1rem;
                        top: 30px; }

                    img {
                        width: 125px;
                        margin-bottom: 1rem;

                        @include mobile {
                            width: 100px; } }

                    .heading {
                        font-size: 18px;
                        color: #ffffff;
                        font-weight: bold;
                        margin-bottom: .25rem; }

                    .sub-heading {
                        text-align: justify;
                        font-size: 14px;
                        color: #ffffff;
                        font-weight: bold; }

                    .regis-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        border: none;
                        border-radius: 2px;
                        background-color: $v4orange2;
                        color: #ffffff;
                        height: 35px;
                        font-size: 14px;
                        font-weight: bold;
                        text-decoration: none; }

                    .regis-button:hover {
                        background-color: $v4orange1;
                        color: #ffffff;
                        text-decoration: none;
                        transition: all 0.3s ease; } } } } } }



