@import '../../App.sass';
@import '../../mq.sass';

.service-page-wrapper {

    .service-page {
        #bg {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            filter: brightness(50%); }

        .page-head-wrapper {
            padding-top: 6rem;
            height: 100vh;

            .page-head {} }

        .feature-section-wrapper {
            padding: 4rem 0;

            .feature-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; }

                .section-title {
                    font-size: 40px;
                    color: $v4green1;
                    font-weight: bold;
                    width: 800px;
                    margin-bottom: 2rem;
                    line-height: 50px;

                    @include mobile {
                        width: 100%;
                        font-size: 28px; }

                    @include tablet {
                        width: 600px;
                        font-size: 32px; } } } }

        .banner-section-wrapper {
            height: 350px;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;

                @include mobile {
                    width: auto;
                    height: 350px; } }

            .banner-section {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 4rem;

                .banner-title {
                    font-size: 18px;
                    color: #ffffff;
                    font-family: $fontMerriweather;
                    width: 300px;
                    margin-bottom: 2rem;

                    @include mobile {
                        font-size: 14px; }

                    @include tablet {
                        font-size: 16px; } } } }

        .customer-section-wrapper {
            padding: 4rem 0 0 0;

            .customer-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; } } }

        .cases-section-wrapper {
            padding-bottom: 4rem;

            .cases-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; }

                .section-title {
                    font-size: 40px;
                    color: $v4green1;
                    font-weight: bold; }

                .section-subtitle {
                    font-size: 18px;
                    color: $v4green1;
                    width: 600px;
                    font-family: $fontMerriweather;
                    margin-bottom: 2rem; } } } } }
