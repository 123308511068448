@import '../../App.sass';
@import '../../mq.sass';

.article-page-wrapper {

    .article-page {

        .article-wrapper {

            .article {
                padding: 0 15rem;
                padding-bottom: 3rem;
                margin-top: 75px;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 7rem; }

                .title {
                    font-size: 28px;
                    margin-bottom: 2rem;
                    font-weight: bold;

                    @include mobile {
                        font-size: 20px;
                        margin-bottom: 1rem; }

                    @include tablet {
                        font-size: 24px; } }

                .author {
                    font-size: 14px;
                    color: #5F6368; }

                .image-wrapper {
                    margin: 1rem 0 2rem;
                    text-align: end;
                    width: 100%;

                    .image {
                        width: 100%; }

                    .source {} } } }


        .more-section-wrapper {
            margin-bottom: 4rem;

            .more-section {
                padding: 0 4rem;

                .more {
                    font-size: 36px;
                    margin-bottom: 2rem;
                    font-weight: bold;
                    color: $v4green1; } } } } }
