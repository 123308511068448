@import '../../../App.sass';
@import '../../../mq.sass';

.service-features-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-between;

    @include mobile {
        justify-content: center; }

    @include tablet {
        justify-content: center; } }
