@import '../../App.sass';
@import '../../mq.sass';

.header {
    margin-top: 1.5rem;

    @include mobile {
        margin-top: 0; }

    @include tablet {
        margin-top: 0; }

    .title {
        color: #ffffff;
        font-weight: bolder;
        font-size: 70px;
        margin-bottom: 1.5rem;

        @include mobile {
            font-size: 40px;
            margin-bottom: .5rem; }

        @include tablet {
            font-size: 50px;
            margin-bottom: 1rem; } }

    .desc {
        color: #ffffff;
        width: 480px;
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 2rem;

        @include mobile {
            font-size: 16px;
            width: 100%;
            padding-right: 2rem; }

        @include tablet {
            font-size: 20px;
            width: 350px; } } }

