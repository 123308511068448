@import "../../App.sass";
@import "../../mq.sass";

.try-page-wrapper {

    .try-page {
        #bg {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            filter: brightness(50%); }

        .page-head-wrapper {
            padding-top: 6rem;
            height: 100vh;

            .page-head {} }

        .product-section-wrapper {
            padding-top: 2rem;

            .product-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; }

                .section-title {
                    font-size: 40px;
                    color: $v4green1;
                    font-weight: bold;

                    @include mobile {
                        font-size: 28px;
                        margin-bottom: 0; }

                    @include tablet {
                        font-size: 32px; } }

                .product-cards {
                    padding: 2rem 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 2rem;

                    @include mobile {
                        padding: 1rem 0;
                        gap: 2rem;
                        justify-content: center; }

                    @include tablet {
                        padding: 1rem 0;
                        gap: 1rem; } } } }

        .service-section-wrapper {

            .service-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; }

                .section-title {
                    font-size: 40px;
                    color: $v4green1;
                    font-weight: bold;

                    @include mobile {
                        font-size: 28px;
                        margin-bottom: 0; }

                    @include tablet {
                        font-size: 32px; } }

                .service-cards {
                    padding: 2rem 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 2rem;

                    @include mobile {
                        padding: 1rem 0;
                        gap: 2rem;
                        justify-content: center; }

                    @include tablet {
                        padding: 1rem 0;
                        gap: 1rem; } } } }

        .redirect-section-wrapper {
            padding: 4rem 0;

            .redirect-section {
                padding: 0 4rem;
                text-align: center;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; }

                .redirect-text {
                    margin-bottom: 2rem;

                    .redirect-title {
                        font-size: 40px;
                        color: $v4green1;
                        font-weight: bold;
                        margin-bottom: 0; }

                    .redirect-subtitle {
                        font-size: 18px;
                        color: $v4green1;
                        font-weight: bold; } } } } } }
