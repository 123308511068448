@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

$greenBG1: #D6EFC7;
$green1: #53BA76;
$green2: #184D47;
$yellow1: #F9C32A;
$yellow2: #FEFFC6;

$v4green1: #06623B;
$v4green2: #1f6650;
$v4green3: #649d66;
$v4green4: #6f9a8d;
$v4green5: #eafbea;
$v4yellow1: #FEFFC6;
$v4yellow2: #f6d743;
$v4yellow3: #f6f578;
$v4yellow4: #FEFFC6;
$v4orange1: #D7451F;
$v4orange2: #EF4D23;
$v4grey1: #5F6368;
$v4grey2: #efefef;
$v4grey3: #757575;

$fontLato: 'Lato', sans-serif;
$fontMerriweather: 'Merriweather', serif;

.App {
    font-family: $fontLato; }

.base-padding {
    padding: 0 4rem; }
