@import '../../App.sass';
@import '../../mq.sass';

.main-card-wrapper {
    border-radius: 3px;
    background-color: #ffffff;

    .main-card {
        display: flex;
        flex-direction: column;
        width: 280px;
        min-height: 325px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        border-radius: 3px;

        @include mobile {
            width: 200px;
            height: 260px; }

        @include tablet {
            width: 240px;
            height: 280px; }

        img {
            width: 100%;
            height: 140px;
            border-radius: 3px 3px 0 0;

            @include mobile {
                height: 100px; }

            @include tablet {
                height: 120px; } }

        .main-card-body {
            flex-grow: 1;
            padding: .5rem 1rem 1rem 1rem;
            display: flex;
            flex-direction: column;

            .main-card-body-text {
                flex-grow: 1;

                .main-card-title {
                    color: $v4green1;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: .25rem;

                    @include mobile {
                        font-size: 14px; }

                    @include tablet {
                        font-size: 16px; } }


                .main-card-desc {
                    color: $v4grey1;
                    font-size: 14px;
                    font-family: $fontMerriweather;
                    width: 100%;
                    margin-bottom: .5rem;

                    @include mobile {
                        font-size: 10px; }

                    @include tablet {
                        font-size: 12px; } } } } } }
