@import "../../App.sass";
@import "../../mq.sass";

.main-button {
    width: fit-content;
    padding: .5rem 1.5rem;
    background-color: $v4orange2;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    border: none;
    border-radius: 3px;

    @include mobile {
        font-size: 14px; }

    @include tablet {
        font-size: 16px; } }

.main-button:hover {
    background-color: $v4orange1;
    color: #ffffff;
    text-decoration: none;
    transition: all 0.3s ease; }


.small.main-button {
    width: fit-content;
    padding: .3rem 1rem;
    background-color: $v4orange2;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    border: none;
    border-radius: 2px; }

.small.main-button:hover {
    background-color: $v4orange1;
    text-decoration: none;
    transition: all 0.3s ease; }

.placement-navbar.main-button {
    width: fit-content;
    height: fit-content;
    padding: .3rem 1rem;
    background-color: $v4orange2;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 2px;

    @include mobile {
        text-align: left;
        padding-left: 0;
        width: 100%; }

    @include tablet {
        text-align: left;
        padding-left: 0;
        width: 100%; } }


.placement-navbar.main-button:hover {
    background-color: $v4orange1;
    text-decoration: none;
    transition: all 0.3s ease; }

