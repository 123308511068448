@import '../../App.sass';
@import '../../mq.sass';

.footer-section-wrapper {
    background-color: $v4green1;
    padding: 4rem 0;

    p, a {
        color: #ffffff;
        margin-bottom: .5rem; }

    .footer-section {
        padding: 0 4rem;

        @include mobile {
            padding: 0 15px; }

        .kota-footer {
            img {
                width: 100px;
                margin-bottom: 1rem; }

            .tagline {
                font-size: 26px;
                font-weight: bold;
                width: 350px;
                line-height: 30px;
                margin-bottom: 1.5rem;

                @include mobile {
                    font-size: 18px;
                    width: 100%;
                    padding-right: 2rem; }

                @include tablet {
                    width: 270px; } }

            .address {

                @include mobile {
                    margin-bottom: 2rem; }

                .text {
                    font-size: 14px; } } }

        .links {
            display: flex;
            justify-content: space-between;

            @include mobile {
                justify-content: flex-start; }

            .link-wrapper {
                display: flex;
                flex-direction: column;

                @include mobile {
                    margin-right: 2rem; }

                .footer-category {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 2rem; }

                .footer-link {
                    text-decoration: none;
                    font-size: 14px; }

                .footer-link:hover {
                    color: #ffffff; } } } } }

.copyright-section-wrapper {
    background-color: $v4orange2;
    padding: 1rem 0;

    .copyright-section {
        padding: 0 4rem;
        display: flex;
        align-items: center;

        @include mobile {
            padding: 0 15px; }

        .buttons {
            flex-grow: 1;
            display: inline;

            .circular {
                color: #ffffff;
                background: none;
                border: none;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                font-size: 24px; } }

        .copyright {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            margin: 0; } } }
