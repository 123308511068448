@import '../../../App.sass';
@import '../../../mq.sass';

.teams {

    .section-title {
        font-size: 40px;
        font-weight: bold;
        color: $v4green1;

        @include mobile {
            font-size: 28px; }

        @include tablet {
            font-size: 32px; } }

    .member-card-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 7.5%;
        justify-content: center;

        .member-card {
            width: 175px;

            @include mobile {
                display: flex;
                width: 100%;
                margin-bottom: 1rem; }

            img {
                width: 100%;
                border-radius: 3px;
                height: 175px;
                margin-bottom: 1rem;

                @include mobile {
                    width: 150px;
                    height: 150px;
                    margin-right: 1rem; } }

            .info {
                padding: 0 .5rem;

                .name {
                    font-size: 20px;
                    line-height: 25px;
                    color: $v4green1;
                    font-weight: bold;
                    height: 50px;
                    margin: 0;

                    @include mobile {
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: .5rem;
                        height: auto; }

                    @include tablet {
                        font-size: 16px;
                        line-height: 18px;
                        margin-bottom: .5rem;
                        height: auto; } }

                .position {
                    font-size: 20px;
                    line-height: 25px;
                    color: $v4green1;
                    height: 50px;

                    @include mobile {
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: .5rem;
                        height: auto; }

                    @include tablet {
                        font-size: 16px;
                        line-height: 18px;
                        margin-bottom: .5rem;
                        height: auto; } }

                .desc {
                    text-align: justify;
                    color: #5F6368; } } } } }
