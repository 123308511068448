@import '../../App.sass';
@import '../../mq.sass';

.register-page-wrapper {
    .register-page {
        #bg {
            z-index: -1;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            filter: brightness(50%);

            @include mobile {
                position: fixed; }

            @include tablet {
                position: fixed; } }

        .register-head-wrapper {
            padding-top: 6rem;
            height: auto;
            margin-bottom: 4rem;

            .register-head {

                .header {
                    margin: 0;

                    .title {
                        color: #ffffff;
                        font-weight: bolder;
                        font-size: 70px;
                        margin-bottom: 2rem;

                        @include mobile {
                            font-size: 40px;
                            margin-bottom: 1rem; }

                        @include tablet {
                            margin-bottom: 1rem;
                            font-size: 50px; } }

                    .benefit-label {
                        color: #ffffff;
                        font-weight: bold;
                        font-size: 36px;
                        width: 100%;
                        height: auto;

                        @include mobile {
                            font-size: 28px;
                            margin-bottom: 1rem; }

                        @include tablet {
                            font-size: 22px;
                            width: 500px;
                            margin-bottom: 1rem; } }

                    .benefits {
                        position: relative;
                        padding: 0 .5rem;

                        @include mobile {
                            margin-bottom: 2rem; }

                        @include tablet {
                            margin-bottom: 2rem; }


                        .benefit {
                            position: relative;
                            display: flex;
                            align-items: baseline;

                            .circle {
                                position: relative;
                                top: 3px;
                                color: #ffffff;
                                font-size: 16px;
                                margin-right: 1rem;

                                @include mobile {
                                    font-size: 12px; }

                                @include tablet {
                                    font-size: 14px; } }

                            .benefit-desc {
                                color: #ffffff;
                                font-size: 20px;

                                @include mobile {
                                    width: 250px;
                                    font-size: 16px; }

                                @include tablet {
                                    font-size: 18px; } } } } } }

            .form-wrapper {

                .form {
                    float: right;
                    background-color: #ffffff;
                    padding: 2rem 1.5rem;
                    border-radius: 5px;
                    width: 500px;
                    margin-right: 40px;

                    @include mobile {
                        margin-right: 0;
                        width: 100%;
                        margin-bottom: 4rem; }

                    @include tablet {
                        margin-right: 0;
                        float: left;
                        margin-bottom: 4rem; }

                    .form-group {
                        margin-bottom: .5rem;

                        .form-label {
                            margin: 0;
                            font-size: 14px;
                            color: $v4green1;
                            font-weight: bold; }

                        .form-control {
                            font-size: 14px;
                            border-radius: 3px;
                            border: 1px solid #9C9C9C;
                            background-color: #ffffff;
                            height: calc(1.5em + .60rem + 2px);
                            padding: .3rem .75rem; }

                        .form-control:focus {
                            border: 1px solid $v4green1;
                            box-shadow: 0px 0px 4px 3px rgba(6, 98, 59, 0.3); }


                        .form-control:visited {
                            border: 1px solid $v4green1;
                            background-color: #ffffff; } }

                    .submit {
                        margin-top: .75rem;
                        background-color: $v4green1;
                        color: #ffffff;
                        width: 100%;
                        height: 35px;
                        font-size: 14px;
                        font-weight: bold;
                        border: none;
                        border-radius: 3px; } } } } } }
