@import '../../App.sass';
@import '../../mq.sass';


.not-found-page-wrapper {

    .not-found-page {

        #bg {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            filter: brightness(50%); }

        .page-head-wrapper {
            padding-top: 8rem;
            height: 100vh;

            .page-head {

                .header {
                    margin-top: 1.5rem;

                    @include mobile {
                        margin-top: 0; }

                    @include tablet {
                        margin-top: 0; }

                    .title {
                        color: #ffffff;
                        font-weight: bolder;
                        font-size: 70px;
                        margin-bottom: 1rem;

                        @include mobile {
                            font-size: 40px;
                            margin-bottom: .5rem; }

                        @include tablet {
                            font-size: 50px;
                            margin-bottom: 1rem; } }

                    .desc {
                        color: #ffffff;
                        width: 480px;
                        font-weight: bold;
                        font-size: 22px;
                        margin-bottom: 0;

                        @include mobile {
                            font-size: 16px;
                            width: 100%;
                            padding-right: 2rem; }

                        @include tablet {
                            font-size: 20px;
                            width: 350px; } }

                    .desc-detail {
                        color: #ffffff;
                        width: 480px;
                        font-weight: bold;
                        font-size: 22px;
                        margin-bottom: 2rem;

                        @include mobile {
                            font-size: 16px;
                            width: 100%;
                            padding-right: 2rem; }

                        @include tablet {
                            font-size: 20px;
                            width: 350px; } }

                    .error {
                        color: #ffffff;
                        width: 480px;
                        font-size: 18px;
                        margin-bottom: 2rem;

                        @include mobile {
                            font-size: 14px;
                            width: 100%;
                            padding-right: 2rem; }

                        @include tablet {
                            font-size: 16px;
                            width: 350px; } } } } } } }

