@import '../../App.sass';
@import '../../mq.sass';

.navbar {
    padding: .25rem 7rem;

    .navbar-brand {
        img {
            width: 75px; } }

    .navbar-links {
        justify-content: space-between;

        .center {
            position: relative;
            left: 30%;

            @include mobile {
                left: 0; }

            @include tablet {
                left: 0; } }

        .nav-link {
            color: #ffffff;
            font-weight: bold;
            font-size: 14px; }

        .btn-custom {
            color: #ffffff;
            font-weight: bold;
            font-size: 14px;
            width: fit-content;

            @include mobile {
                text-align: left;
                width: 100%;
                padding: 8px 12px 8px 0; }

            @include tablet {
                text-align: left;
                width: 100%;
                padding: 8px 12px 8px 0; } }


        .right {
            position: relative;
            display: flex;
            align-items: baseline;

            .nav-link {
                color: #ffffff;
                font-weight: bold;
                height: 40px; } } }

    .btn.focus, .btn:focus {
        box-shadow: none; } }

.navbar.transparent-navbar.navbar-dark {
    padding: .25rem 7rem;
    background: transparent;
    transition: all 0.3s ease;

    @include mobile {
        padding: .25rem 1rem; }

    @include tablet {
        padding: .25rem 1rem; }

    .nav-link {
        color: #ffffff;
        font-weight: bold;
        font-size: 14px; } }


.navbar.green-navbar.navbar-dark {
    padding: .25rem 7rem;
    background-color: $v4green1;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease;

    @include mobile {
        padding: .25rem 1rem; }

    @include tablet {
        padding: .25rem 1rem; }

    .nav-link {
        color: #ffffff;
        font-weight: bold;
        font-size: 14px; } }
