@import "../../App.sass";
@import "../../mq.sass";

.about-us-page-wrapper {

    .about-us-page {

        #bg {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            filter: brightness(50%); }

        .page-head-wrapper {
            padding-top: 6rem;
            height: 100vh;

            .page-head {} }

        .profile-section-wrapper {
            background: linear-gradient(to left bottom, $v4green1 50%, #ffffff 50%);
            padding: 2rem 4rem 2rem;
            height: calc(800px + 2rem + 2rem);

            @include mobile {
                padding: 0 15px;
                padding-top: 15px; }

            @include tablet {
                padding: 0 15px;
                padding-top: 15px; }


            .profile-section {
                position: relative;

                .paragraph {
                    width: 400px;

                    @include mobile {
                        width: 250px; }

                    @include tablet {
                        width: 300px; }

                    .paragraph-title {
                        font-size: 32px;

                        @include mobile {
                            font-size: 20px; }

                        @include tablet {
                            font-size: 26px; } }

                    .paragraph-subtitle {
                        font-size: 14px;

                        @include mobile {
                            font-size: 12px; }

                        @include tablet {
                            font-size: 14px; } } }

                .who-kota {
                    position: absolute;
                    right: 4rem;
                    top: 87px;
                    color: #ffffff;
                    text-align: end;

                    @include mobile {
                        right: 30px;
                        top: 0; }

                    @include tablet {
                        top: 15px;
                        right: 15px; } }


                .history-kota {
                    position: absolute;
                    left: 4rem;
                    bottom: 0;
                    color: $v4green1;

                    @include mobile {
                        left: 30px; }

                    @include tablet {
                        left: 15px; } }

                .box {
                    position: absolute;
                    background-color: #ffffff;
                    width: 170px;
                    height: 170px;
                    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    @include mobile {
                        width: 125px;
                        height: 125px; }

                    @include tablet {
                        width: 150px;
                        height: 150px; }

                    .fp-number {
                        color: $v4green1;
                        font-size: 58px;
                        font-weight: bold;
                        margin-bottom: 0;
                        line-height: 50px;

                        @include mobile {
                            font-size: 40px; }

                        @include tablet {
                            font-size: 50px; } }

                    .fp-desc {
                        color: $v4green1;
                        margin-bottom: 0;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                        width: 75px;

                        @include mobile {
                            font-size: 14px; }

                        @include tablet {
                            font-size: 16px; } } }

                .box-1 {
                    top: 87px;

                    @include mobile {
                        top: 275px;
                        left: 30px; }

                    @include tablet {
                        top: 15px;
                        left: 15px; } }

                .box-2 {
                    top: calc(87px + 170px - 10px);
                    left: calc(170px + 75px);


                    @include mobile {
                        top: calc(275px + 125px + 25px);
                        left: 30px; }

                    @include tablet {
                        top: calc(15px + 150px + 50px);
                        left: calc(15px + 150px + 30px); } }

                .box-3 {
                    top: calc(87px + 170px * 2 - 10px * 2);
                    left: calc(170px * 2 + 75px * 2);

                    @include mobile {
                        top: 275px;
                        left: calc(100% - 125px - 30px); }

                    @include tablet {
                        top: calc(15px + 150px * 2 + 50px * 2);
                        left: calc(15px + 150px * 2 + 30px * 2); } }

                .box-4 {
                    top: calc(87px + 170px * 3 - 10px * 3);
                    left: calc(170px * 3 + 75px * 3);

                    @include mobile {
                        top: calc(275px + 125px + 25px);
                        left: calc(100% - 125px - 30px); }

                    @include tablet {
                        top: calc(15px + 150px * 3 + 50px * 3);
                        left: calc(15px + 150px * 3 + 30px * 3); } } } }

        .teams-section-wrapper {

            .teams-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; } } }

        .redirect-section-wrapper {

            .redirect-section {
                padding: 0 4rem 4rem;

                @include mobile {
                    padding: 0 15px 4rem; }

                @include tablet {
                    padding: 0 15px 4rem; }

                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .redirect-title {
                        text-align: center;
                        font-size: 20px;
                        color: $v4green1;
                        font-weight: bold;
                        width: 475px;

                        @include mobile {
                            font-size: 14px;
                            width: 100%;
                            padding: 0 1rem; }

                        @include tablet {
                            font-size: 16px; } } } } } } }
