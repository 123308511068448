@import "../../App.sass";
@import "../../mq.sass";

.home-page-wrapper {

    .home-page {
        #bg {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            filter: brightness(50%); }

        .page-head-wrapper {
            padding-top: 6rem;
            height: 100vh;

            .page-head {} }

        .product-section-wrapper {
            position: relative;
            height: 100vh;

            @include mobile {
                height: 75vh; }

            @include tablet {
                height: 70vh; }


            #product-section-bg {
                width: 100%;
                height: 100%;

                .green-bg {
                    background-color: $v4green1;
                    height: 50%;
                    width: 100%; }

                .white-bg {
                    background-color: #ffffff;
                    height: 50%;
                    width: 100%; } }

            .product-section {
                padding: 0 4rem;
                position: relative;
                width: 100%;
                top: -100vh;
                left: 0;

                @include mobile {
                    top: -75vh;
                    padding: 0 15px; }

                @include tablet {
                    top: -70vh;
                    padding: 0 15px; }

                .product {
                    position: absolute;
                    top: 20px; } } }

        .services-section-wrapper {

            .services-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; } } }


        .customer-section-wrapper {
            padding-top: 0;
            padding-bottom: 2rem;

            .customer-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; } } }

        .testimony-section-wrapper {
            margin-bottom: 4rem;

            .testimony-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; }

                .testimony-wrapper {
                    height: 350px;
                    position: relative;
                    box-shadow: -10px 10px 30px 2px rgba(0, 0, 0, 0.25);

                    @include mobile {
                        height: 150px; }

                    @include tablet {
                        height: 250px; } }

                .title {
                    font-size: 40px;
                    color: $v4green1;
                    font-weight: bold;

                    @include mobile {
                        font-size: 28px; }

                    @include tablet {
                        font-size: 32px; } } } }

        .topic-section-wrapper {
            padding: 2rem 0 4rem 0;

            .topic-section {
                padding: 0 4rem;

                @include mobile {
                    padding: 0 15px; }

                @include tablet {
                    padding: 0 15px; } } } } }

